import { VueRouter, vuexOidcCreateRouterMiddleware, Vue } from '@oel/common'
import OidcCallback from '../components/oidc/OidcCallback.vue'
import OidcSilentRenew from '../components/oidc/OidcSilentRenew.vue'
import store from '../store'
import { Subjects, Actions } from '@/config/policy'
import { NotFound, NotAuthorized, SignedOut } from '@oel/ui-toolkit'
import { defineRulesFor } from '@/config/ability'

import * as usersApi from '../api/users'

const Home = () => import('../components/home/home.vue')
const Contact = () => import('../components/contact/contact-us.vue')
const Account = () => import('../components/account/account.vue')
const ProviderDashboard = () => import('../components/dashboard/provider/provider-dashboard.vue')
const NonProviderDashboard = () => import('../components/dashboard/oel-elc/dashboard.vue')
const OidcSignout = () => import(/* webpackChunkName: "oidcSignout" */ '../components/oidc/OidcSignout.vue')
const OidcSignoutRedirect = () => import(/* webpackChunkName: "oidcSignoutRedirect" */ '../components/oidc/OidcSignoutRedirect.vue') // for clearing QPS auth properties after another module has signed out

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      isPublic: true,
      showNavigation: true
    }
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: Contact,
    meta: {
      isPublic: true,
      showNavigation: true
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    beforeEnter: (to, from, next) => {
      if (store.getters.ability.can(Actions.Read, Subjects.Account)) {
        next()
      } else {
        next({ name: 'NotAuthorized' })
      }
    },
    meta: {
      showNavigation: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    beforeEnter: async (to, from, next) => {
      const userRoles = [store.getters?.oidcUser?.role ?? []].flat()

      if (userRoles.includes('Provider')) {
        const res = await usersApi.getUserById(store.getters?.oidcUser.sub)

        if (res?.data?.providerProfileId) {
          const providerProfileId = res.data.providerProfileId
          next({ name: 'ProviderDashboard', params: { providerProfileId: providerProfileId } })
        } else {
          next({ name: 'NonProviderDashboard' })
        }
      } else {
        next({ name: 'NonProviderDashboard' })
      }
    },
    meta: {
      showNavigation: false
    }
  },
  {
    path: '/dashboard/provider/:providerProfileId',
    name: 'ProviderDashboard',
    component: ProviderDashboard,
    beforeEnter: (to, from, next) => {
      if (store.getters.ability.can(Actions.Read, Subjects.ProviderDashboard)) {
        next()
      } else {
        next({ name: 'NotAuthorized' })
      }
    },
    meta: {
      showNavigation: true
    }
  },
  {
    path: '/dashboard/oel-elc/',
    name: 'NonProviderDashboard',
    component: NonProviderDashboard,
    beforeEnter: (to, from, next) => {
      if (store.getters.ability.can(Actions.Read, Subjects.NonProviderDashboard)) {
        next()
      } else {
        next({ name: 'NotAuthorized' })
      }
    },
    meta: {
      showNavigation: true
    }
  },
  {
    path: '/oidc-callback',
    name: 'oidcCallback',
    component: OidcCallback,
    meta: {
      isPublic: true,
      showNavigation: false,
      oidcRoute: true
    }
  },
  {
    path: '/oidc-silent-renew',
    name: 'oidcSilentRenew',
    component: OidcSilentRenew,
    meta: {
      isPublic: true,
      showNavigation: false,
      oidcRoute: true
    }
  },
  {
    path: '/not-authorized',
    name: 'NotAuthorized',
    component: NotAuthorized,
    meta: {
      isPublic: true,
      showNavigation: true
    }
  },
  {
    path: '/oidc-signout',
    name: 'OidcSignout',
    component: OidcSignout,
    meta: {
      isPublic: true,
      showNavigation: false,
      oidcRoute: true
    }
  },
  {
    path: '/oidc-signout-redirect',
    name: 'OidcSignoutRedirect',
    component: OidcSignoutRedirect,
    meta: {
      isPublic: true,
      showNavigation: false,
      oidcRoute: true
    }
  },
  {
    path: '/signed-out',
    name: 'SignedOut',
    component: SignedOut,
    redirect: { name: 'Home' },
    meta: {
      isPublic: true,
      showNavigation: true
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      isPublic: true,
      showNavigation: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  try {
    await Vue.prototype.$oidcUserManager.clearStaleState()
  } catch (err) {
    console.error(
      'Could not clear stale state entries in storage for incomplete authorize requests'
    )
  }

  const oidcUserKey = Object?.keys(localStorage)?.filter(k => k.startsWith('oidc.user'))[0]
  if (!(to.meta?.oidcRoute || from.meta?.oidcRoute) && oidcUserKey) {
    try {
      await store.dispatch('authenticateOidcSilent')
      const ability = store.getters.ability
      const rules = defineRulesFor(store.getters?.oidcUser?.role)
      ability.update(rules)
      store.commit('SET_ABILITY_RULES', rules)
      next()
    } catch (payload) {
      if (payload?.error === 'login_required') {
        localStorage.removeItem(oidcUserKey)
        store.dispatch('removeOidcUser')
        router.push({ name: 'Home' }).catch()
      } else {
        router.push({ name: 'NotAuthorized' }).catch()
      }
    }
  } else {
    next()
  }
})

router.beforeEach(vuexOidcCreateRouterMiddleware(store))

router.afterEach((to) => {
  if (to.name !== 'OidcSignoutRedirect') {
    store.commit('SET_ROUTING_FINISHED')
  }
})

export { router, VueRouter }
