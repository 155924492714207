<template>
  <div v-if="!iconView">
    <v-row justify="start"
         align-content="space-between">
    <v-col cols="2"
           align="center"
           class="mt-2">
      <v-progress-circular v-if="!isSaved"
                           :size="15"
                           indeterminate
                           color="primary"
                           class="mr-1" />
      <div v-else>
        <transition name="fade">
          <v-icon v-if="showSaveSuccess"
                  class="mr-1"
                  color="success">
            mdi-checkbox-marked-circle
          </v-icon>
        </transition>
      </div>
    </v-col>
    <v-col cols="10"
           align="center">
      <div class="subtitle-1 mt-2 text-left">
        {{ isSaved ? savedText : savingText }}
      </div>
    </v-col>
  </v-row>
  </div>
  <div v-else>
    <v-tooltip left>
      <template #activator="{ on }">
        <span v-on="on">
          <v-progress-circular v-if="!isSaved"
                           :size="15"
                           indeterminate
                           color="primary"
                           class="ml-1" />
          <div v-else>
            <v-icon color="success"
                    class="ml-1">
                mdi-checkbox-marked-circle
            </v-icon>
          </div>
        </span>
      </template>
      <span class="text-caption text-sm-body-2">{{ isSaved ? savedText : savingText }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    savedText: String,
    savingText: String,
    iconView: Boolean
  },
  data() {
    return {
      showSaveSuccess: false
    }
  },
  computed: {
    isSaved() {
      return this.$store.state.isSaved
    }
  },
  watch: {
    // each time isSaved changes TO true, briefly show success indicator
    isSaved() {
      if (this.isSaved) {
        this.showSaveSuccess = true
        setTimeout(() => {
          this.showSaveSuccess = false
        }, 2000)
      } else {
        this.showSaveSuccess = false
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
