import store from '../store'
import { axios, Vue } from '@oel/common'
import { router } from '@/router'

export const setAxiosRequestInterceptor = () => {
  axios.interceptors.request.use(
    config => {
      const token = store.getters.oidcAccessToken
      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    response => response,
    function(error) {
      if ([401, 403].includes(error?.response?.status)) {
        router.push({ name: 'NotAuthorized' })
      } else {
        const errorMessage = error?.response?.data?.errors?.providerProfileId?.join('; ') ??
          error?.response?.data?.detail ??
          'An error occurred in api call: ' + (error?.response?.statusText ?? error)
        Vue.prototype.$vToastify.error({ body: errorMessage, canTimeout: false })
      }

      return Promise.reject(error)
    }
  )
}
