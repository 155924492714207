import { Vue, Vuex } from '@oel/common'
import users from './modules/users'
import { getField, updateField } from 'vuex-map-fields'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { oidcSettings } from '../config/oidc'
import { Ability } from '@casl/ability'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import config from '@/config'
import { router } from '@/router'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

// encryption for vuex persisted state
const ls = new SecureLS({
  encodingType: 'aes',
  isCompression: false,
  encryptionSecret: config.vuexEncryptionKey
})

// STATE
const state = {
  qps_rules: '',
  routingToggle: false
}

// MUTATIONS
const mutations = {
  updateField,
  SET_ABILITY_RULES: (state, rules) => {
    state.qps_rules = rules
  },
  SET_ROUTING_FINISHED: (state) => { state.routingToggle = !state.routingToggle }
}

// ACTIONS
const actions = ({})

// GETTERS
const getters = {
  getField,
  ability() {
    return new Ability()
  },
  getCaslRules: (state) => {
    return state.qps_rules
  },
  routeFinished: (state) => state.routingToggle
}

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['qps_rules'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  modules: {
    users,
    oidcStore: vuexOidcCreateStoreModule(
      oidcSettings,
      {
        dispatchEventsOnWindow: true
      },
      {
        // userLoaded: (user) => console.log('OIDC user is loaded:', user),
        // userUnloaded: () => console.log('OIDC user is unloaded'),
        // oidcError: (payload) => console.log(`An OIDC error occured at ${payload?.context}:`, payload?.error),
        // accessTokenExpiring: () => console.log('Access token will expire'),
        // accessTokenExpired: () => console.log('Access token did expire'),
        // silentRenewError: () => console.log('OIDC user is unloaded'),
        automaticSilentRenewError: (payload) => payload?.error === 'login_required' && router.currentRoute.name !== 'SignedOut' && router.currentRoute.name !== 'OidcSignoutRedirect' ? router.push({ name: 'OidcSignoutRedirect' }) : null
      }
    )
  },
  state,
  getters,
  mutations,
  actions,
  strict: debug
})

export default store
