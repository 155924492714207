export default {
  apiUrl: 'https://hotfix-gateway-api.oel.local/',
  oidcAuthority: 'https://hotfix-auth-app.oel.local/',
  oidcRedirectUri: 'https://hotfix-qps-app.oel.local/oidc-callback',
  oidcSilentRedirectUri: 'https://hotfix-qps-app.oel.local/oidc-silent-renew',
  oidcPostLogoutRedirectUri: 'https://hotfix-qps-app.oel.local/oidc-signout',
  oidcScopes: 'openid profile qpsScope roles',
  vuexEncryptionKey: 'C0HhdGsw8f7ntvLxT2UuCNpEOAkszL395BhUV',
  bpieceUrl: 'https://hotfix-bpiece-app.oel.local/',
  pyramidUrl: 'https://hotfix-pyramid-app.oel.local/',
  fsu10Url: 'https://hotfix-fsu10-app.oel.local/',
  qpsUrl: 'https://hotfix-qps-app.oel.local/',
  srpaUrl: 'https://hotfix-qps-appstest.oel.local/',
  qpsSignoutRedirectUrl: 'https://hotfix-qps-app.oel.local/oidc-signout-redirect',
  showEnvironmentBanner: true,
  environmentDescription: 'Hotfix'
}
