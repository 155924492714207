<template>
  <div>
    <div class="hidden-md-and-up text-xs-left">
      <span>{{ label }}</span><br>
      <v-text-field v-model="date"
                    type="date"
                    name="datePicker"
                    :disabled="disabled"
                    :rules="rules"
                    :error-messages="isValidDate()"
                    :clearable="clearable"
                    prepend-icon="mdi-calendar"
                    @change="onChange" />
    </div>
    <v-layout class="hidden-sm-and-down">
      <v-menu ref="menu"
              :return-value.sync="date"
              :close-on-content-click="false"
              :nudge-right="25"
              :disabled="disabled"
              transition="scale-transition"
              min-width="290px"
              class="mr-2">
        <template #activator="{ on }">
          <v-text-field ref="textField"
                        v-model="dateFormatted"
                        :disabled="disabled"
                        :label="label"
                        :rules="rules"
                        :error-messages="isValidDate()"
                        @change="onTextChange"
                        v-on="on"
                        :clearable="clearable"
                        prepend-icon="mdi-calendar" />
        </template>
        <v-date-picker v-model="date"
                       no-title
                       :max="max"
                       :min="min"
                       @input="onChange" />
      </v-menu>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Pick a date'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    max: {
      type: String,
      default: ''
    },
    min: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    date: '',
    dateFormatted: '',
    datePicker: false
  }),
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date)
    },
    value() {
      this.setDateFromValue()
    }
  },
  created: function() {
    this.setDateFromValue()
  },
  methods: {
    setDateFromValue() {
      if (this.value != null) {
        this.date = (' ' + this.value).slice(1)
        this.$emit('input', this.date)
      } else {
        this.date = ''
      }
    },
    onChange() {
      this.$refs.menu.save(this.date)
      this.$emit('input', this.date)
      this.datePicker = false
    },
    onTextChange() {
      this.date = this.parseDate(this.dateFormatted)
      this.$refs.menu.save(this.date)
      this.$emit('input', this.date)
      this.datePicker = false
    },
    formatDate(date) {
      if (!date) {
        return
      }
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    parseDate(date) {
      if (!date) {
        return
      }
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    isValidDate() {
      if (!this.dateFormatted) {
        return []
      }
      const invalidDate = 'Date must be formatted MM/DD/YYYY'
      const dateString = this.dateFormatted
      // First check for the pattern
      // This regex allows single-digit month and day
      // var dateRegEx = '/^\d{1,2}\/\d{1,2}\/\d{4}$/'
      // This regex forces two-digit month and day
      const dateRegEx = /^(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\d\d$/
      if (!dateRegEx.test(dateString)) {
        return invalidDate
      }

      // Parse the date parts to integers
      const parts = dateString.split('/')
      const day = parseInt(parts[1], 10)
      const month = parseInt(parts[0], 10)
      const year = parseInt(parts[2], 10)

      // Check the ranges of month and year
      if (year < 1000 || year > 3000 || month === 0 || month > 12) {
        return invalidDate
      }

      const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

      // Adjust for leap years
      if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
        monthLength[1] = 29
      }

      // Check the range of the day
      return day > 0 && day <= monthLength[month - 1] ? [] : invalidDate
    }
  }
}
</script>

<style scoped>
.calendar-icon {
  padding-top: 1em;
}
</style>
