<template>
  <div class="img-container">
    <v-container>
      <v-row justify="center">
        <v-col cols="auto">
          <v-card class="opaque mt-5"
                  max-width="800"
                  elevation="10">
            <v-card-title>
              <h5 class="text-left display-2 primary--text">
                Resource Not Found
              </h5>
            </v-card-title>
            <v-card-text class="text-left">
              <p class="text-subtitle-1">
                The resource that was requested could not be found. Please contact your early learning coalition
                if you believe that there is an error with the Quality Performance System.
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  emits: ['page:initialized'],
  mounted: function() {
    // Wait until child components have loaded, then emit state to app
    this.$nextTick(function() {
      this.$emit('page:initialized')
    })
  }
}
</script>

<style scoped>
</style>
