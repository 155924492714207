<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="auto">
        <v-card outlined
                max-width="500"
                tile
                class="mt-5">
          <v-card-title>
            Signed Out
          </v-card-title>
          <v-card-text class="text-left">
            <p>Your account has been signed out of this application.</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  emits: ['page:initialized'],
  mounted: function() {
    // Wait until child components have loaded, then emit state to app
    this.$nextTick(function() {
      this.$emit('page:initialized')
    })
  }
}
</script>

<style scoped>
</style>
