<template>
  <!-- Unnecessary v-model -> buttons below -->
  <v-dialog :value="value"
            :max-width="options.width"
            @keydown.esc="cancel"
            @click:outside="cancel">
    <v-card>
      <v-card-title primary-title
                    class="primary--text title">
        {{ title }}
      </v-card-title>
      <div class="px-2">
        <v-divider />
      </div>
      <v-card-text v-show="!!message"
                   class="text-left py-2 px-6">
        {{ message }}
      </v-card-text>
      <div class="px-2">
        <v-divider />
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary"
               dark
               @click.native="yes">
          Yes
        </v-btn>
        <v-btn text
               color="primary"
               dark
               @click.native="cancel">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: ['title', 'message', 'value'],
  data: () => ({
    options: {
      color: 'primary',
      width: 500
    },
    confirmed: false
  }),
  methods: {
    yes() {
      this.$emit('confirm-dialog-closed', true)
      this.$emit('input', false)
    },
    cancel() {
      this.$emit('confirm-dialog-closed', false)
      this.$emit('input', false)
    }
  }
}
</script>
