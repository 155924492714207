import config from '.'

export const oidcSettings = {
  authority: config.oidcAuthority,
  clientId: 'qps-web',
  redirectUri: config.oidcRedirectUri,
  silentRedirectUri: config.oidcSilentRedirectUri,
  postLogoutRedirectUri: config.oidcPostLogoutRedirectUri,
  responseType: 'code',
  scope: config.oidcScopes,
  automaticSilentSignin: false,
  automaticSilentRenew: true,
  loadUserInfo: true,
  monitorSession: false
}
