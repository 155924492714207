<template>
  <div>
    <v-menu ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="500px"
            min-width="500px">
      <template #activator="{ on, attrs }">
        <v-text-field v-model="timeForTextField"
                      :label="label"
                      :clearable="clearable"
                      :rules="rules"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on" />
      </template>
      <v-time-picker v-if="menu2"
                     v-model="time"
                     full-width
                     scrollable
                     :ampm-in-title="ampmInTitle"
                     :landscape="$vuetify.breakpoint.mdAndUp">
        <v-btn text
               color="primary"
               @click="saveTime(time)">
          OK
        </v-btn>
        <v-spacer />
        <v-btn text
               color="primary"
               @click="menu2 = false">
          Cancel
        </v-btn>
      </v-time-picker>
    </v-menu>
  </div>
</template>

<script>
import { clientUtils } from '@oel/common'

export default {
  emits: ['input'],
  props: {
    value: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Pick a time'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayAs12hr: {
      type: Boolean,
      default: false
    },
    ampmInTitle: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    time: null,
    menu2: false
  }),
  watch: {
    time() {
      this.$emit('input', this.time)
    },
    value() {
      this.setTimeFromValue()
    }
  },
  created: function() {
    this.setTimeFromValue()
  },
  computed: {
    timeForTextField: {
      get: function() {
        return this.displayAs12hr
          ? clientUtils.format24rTimeForDisplay(this.time)
          : this.time
      },
      set: function(time) {
        this.time = time
      }
    }
  },
  methods: {
    setTimeFromValue() {
      if (this.value != null) {
        this.time = this.value
      } else {
        this.time = null
      }
    },
    saveTime(time) {
      this.$refs.menu.save(time)
    }
  }
}
</script>
