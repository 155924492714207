<script>
import VueToastify from 'vue-toastify'
// Currently, we are simply exporting the base Toastify package. But the intention
// is that in the future we will modify the style and possibly behavior of the
// toastify component so that all dependent packages require the minimal amount of
// effort to use this.
// The readme for this package explains how to modify the styles:
// https://github.com/nandi95/vue-toastify#readme
export default VueToastify
</script>

<style>
    .vt-theme-dark>.vt-content>.vt-paragraph,
    .vt-theme-dark>.vt-content>.vt-title,
    .vt-theme-light>.vt-content>.vt-paragraph,
    .vt-theme-light>.vt-content>.vt-title {
        font-family:Roboto,sans-serif!important;
    }
</style>
