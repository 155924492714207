<template>
  <div>
    <v-app-bar class="center-nav shrink"
               tile
               elevation="3"
               color="primary"
               dark
               app>
      <v-row justify="space-between"
             align="center"
             v-if="!loading && !navigationBarAction && initialization">
        <v-col cols="12"
               v-if="!$vuetify.breakpoint.lgAndUp">
          <v-row dense>
            <v-col cols="auto">
              <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="10"
               v-if="$vuetify.breakpoint.lgAndUp">
          <v-row justify="start"
                 v-if="user">
            <v-col cols="auto">
              <v-btn text
                     link
                     :href="homeLink">
                Home
              </v-btn>
            </v-col>
            <v-col cols="auto"
                   v-if="showStaff">
              <v-btn text
                     link
                     :href="staffLink">
                Staff
              </v-btn>
            </v-col>
            <v-col cols="auto"
                   v-if="showClassrooms">
              <v-btn text
                     link
                     :href="classroomsLink">
                Classrooms
              </v-btn>
            </v-col>
            <v-col cols="auto"
                   v-if="roleSections.length">
              <v-menu offset-y
                      bottom
                      close-on-content-click>
                <template #activator="{ on, attrs }">
                  <v-btn text
                         v-bind="attrs"
                         v-on="on">
                    Program Assessment
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(section, index) in roleSections"
                               :key="`section${index}`"
                               link
                               :href="section.link"
                               class="text-left">
                    <v-list-item-title>{{ section.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col cols="auto"
                   v-if="showCalculator">
              <v-btn text
                     link
                     :href="calculatorLink"
                     :disabled="!calculatorLink || calculatorLink.length === 0">
                Tuition Calculator
              </v-btn>
            </v-col>
            <v-col cols="auto"
                   v-for="(module, index) in roleModules"
                   :key="`module${index}`">
              <v-btn text
                     link
                     :href="module.link"
                     :outlined="module.root === home"
                     @click="v => $emit('enter-module', module.root)">
                {{ module.name }}
              </v-btn>
            </v-col>
            <v-col cols="auto"
                   v-for="(module, index) in roleUserManagement"
                   :key="`userModule${index}`">
              <v-btn text
                     link
                     :href="module.link"
                     :outlined="module.root === home"
                     @click="v => $emit('enter-module', module.root)">
                {{ module.name }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="start"
                 v-if="!loading && !user">
            <v-col cols="auto">
              <v-btn text
                     link
                     :href="homeLink">
                Home
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn text
                     link
                     :href="activateAccountLink">
                Activate Account
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn text
                     link
                     :href="contactUsLink">
                Contact Us
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="2"
               v-if="$vuetify.breakpoint.lgAndUp">
          <v-menu v-if="user"
                  offset-x
                  left
                  :close-on-content-click="true"
                  class="user-menu">
            <template #activator="{ on, attrs }">
              <v-btn v-for="(module, index) in currentModule"
                     link
                     text
                     :x-small="$vuetify.breakpoint.mdAndDown"
                     :key="`module${index}`"
                     :href="module.trainingLink"
                     target="_blank">
                <v-icon left>
                  mdi-book-open-variant
                </v-icon>
                Training
              </v-btn>
              <v-btn icon
                     :large="$vuetify.breakpoint.xlOnly"
                     v-bind="attrs"
                     v-on="on">
                <v-icon dark
                        large>
                  mdi-account-circle
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group active-class="deep-purple--text text--accent-4">
                <v-list-item inactive
                             disabled
                             color="primary"
                             v-if="user && userFullName.length"
                             class="text-left">
                  <v-list-item-title>{{ userFullName }}</v-list-item-title>
                </v-list-item>
                <v-divider v-if="user && userFullName.length" />
                <v-list-item v-for="(action, index) in roleActions"
                             :key="`action${index}`"
                             link
                             :href="action.link"
                             class="text-left">
                  <v-list-item-title>{{ action.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="user"
                             @click.stop="userSignOut"
                             class="text-left">
                  <v-list-item-title>Sign Out</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
          <v-btn :x-small="$vuetify.breakpoint.mdAndDown"
                 light
                 @click.stop="userLogin"
                 v-else>
            <v-icon left
                    dark>
              mdi-lock
            </v-icon>
            Login
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="end"
             v-else>
        <v-col cols="1">
          <v-progress-circular indeterminate
                               color="white" />
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer"
                         temporary
                         class="fixed-nav-drawer"
                         v-if="!loading && initialization">
      <v-list nav
              dense
              v-if="user">
        <v-list-item-group v-model="group"
                           active-class="deep-purple--text text--accent-4">
          <v-list-item link
                       :href="homeLink">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="showStaff"
                       link
                       :href="staffLink">
            <v-list-item-title>Staff</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="showClassrooms"
                       link
                       :href="classroomsLink">
            <v-list-item-title>Classrooms</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="showCalculator"
                       link
                       :href="calculatorLink">
            <v-list-item-title>Tuition Calculator</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="(module, index) in roleModules"
                       :key="`drawerModule${index}`"
                       list
                       :href="module.link"
                       @click="v => $emit('enter-module', module.root)">
            <v-list-item-title :class="module.root === home ? 'deep-purple--text text--accent-4' : ''">
              {{ module.name }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-for="(module, index) in roleUserManagement"
                       :key="`drawerUserModule${index}`"
                       list
                       :href="module.link"
                       @click="v => $emit('enter-module', module.root)">
            <v-list-item-title :class="module.root === home ? 'deep-purple--text text--accent-4' : ''">
              {{ module.name }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-for="(module, index) in currentModule"
                       :key="`module${index}`"
                       link
                       :href="module.trainingLink"
                       target="_blank">
            <v-list-item-title>
              <v-icon
                dense>
                mdi-book-open-variant
              </v-icon>
              Training
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-divider v-if="roleSections.length" />
        <v-list-group :value="false"
                      v-if="roleSections.length">
          <template #activator>
            <v-list-item-title>Program Assessment</v-list-item-title>
          </template>
          <v-list-item v-for="(section, index) in roleSections"
                       :key="`drawerSection${index}`"
                       link
                       :href="section.link">
            <v-list-item-title>{{ section.name }}</v-list-item-title>
          </v-list-item>
          </v-list-group>
        <v-divider />
        <v-list-item-group v-model="actions"
                           active-class="deep-purple--text text--accent-4">
          <v-list-item v-for="(action, index) in roleActions"
                       :key="`drawerAction${index}`"
                       link
                       :href="action.link">
            <v-list-item-title>{{ action.name }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click.stop="v => $emit('user-signout', v)">
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list nav
              dense
              v-else>
        <v-list-item-group>
          <v-list-item link
                       :href="homeLink">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item link
                       :href="activateAccountLink">
            <v-list-item-title>Activate Account</v-list-item-title>
          </v-list-item>
          <v-list-item link
                       :href="contactUsLink">
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group v-model="actions"
                           active-class="deep-purple--text text--accent-4">
          <v-list-item @click.stop="v => $emit('user-login', v)">
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  emits: ['user-signout', 'user-login', 'enter-module'],
  props: {
    home: {
      type: String,
      default: ''
    },
    user: Object,
    config: Object,
    userProvider: {
      type: Object,
      default: () => ({
        providerProfileId: 0,
        isFsu10Enabled: false,
        isPyramidModelEnabled: false,
        isSRPAEnabled: false
      })
    },
    loading: Boolean,
    initialization: Boolean
  },
  data: () => ({
    drawer: false,
    group: null,
    actions: null,
    sections: null,
    navbarAction: false,
    programAssessmentSections: [
      {
        name: 'Providers',
        link: 'elc-oel',
        allowedRoles: [
          'OEL Administrator',
          'OEL Reviewer',
          'OEL Read Only',
          'ELC Administrator',
          'ELC Reviewer',
          'ELC Read Only'
        ],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'Monthly Rosters',
        link: 'elc-oel/monthly-rosters',
        allowedRoles: [
          'OEL Administrator',
          'OEL Reviewer',
          'OEL Read Only',
          'ELC Administrator',
          'ELC Reviewer',
          'ELC Read Only'
        ],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'Provider File Upload',
        link: 'elc-oel/provider-file-upload',
        allowedRoles: ['OEL Administrator'],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'Merge Provider Accounts',
        link: 'elc-oel/transfer-ownership',
        allowedRoles: [
          'OEL Administrator',
          'ELC Administrator'
        ],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'Undo Merge Provider Accounts',
        link: 'elc-oel/undo-transfer-ownership',
        allowedRoles: [
          'OEL Administrator',
          'ELC Administrator'
        ],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'Manage Strategies',
        link: 'elc-oel/strategy-manager',
        allowedRoles: [
          'OEL Administrator',
          'ELC Administrator'
        ],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'Reports',
        link: 'elc-oel/reports',
        allowedRoles: [
          'OEL Administrator',
          'OEL Reviewer',
          'OEL Read Only',
          'ELC Administrator',
          'ELC Reviewer',
          'ELC Read Only'
        ],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'Score Range Thresholds',
        link: 'elc-oel/score-ranges',
        requiresProviderProfileId: false,
        requiredRoles: ['OEL Administrator', 'QA Administrator'],
        allowedRoles: [
          'OEL Administrator',
          'QA Administrator'
        ],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.requiredRoles.every(v => roles.includes(v))
          }
        }
      },
      {
        name: 'WELS Events Dashboard',
        link: 'elc-oel/wels-dashboard',
        requiresProviderProfileId: false,
        allowedRoles: [
          'OEL Administrator',
          'OEL Reviewer',
          'OEL Read Only',
          'ELC Administrator',
          'ELC Reviewer',
          'ELC Read Only'
        ],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'Enable/Disable Coalitions',
        link: 'elc-oel/prod-validation-config',
        requiresProviderProfileId: false,
        requiredRoles: ['OEL Administrator', 'QA Administrator'],
        allowedRoles: [
          'OEL Administrator',
          'QA Administrator'
        ],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.requiredRoles.every(v => roles.includes(v))
          }
        }
      },
      {
        name: 'PA Home',
        link: 'provider/:providerProfileId/home',
        allowedRoles: ['Provider'],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'Provider Profile',
        link: 'provider/:providerProfileId/profile',
        allowedRoles: ['Provider'],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'Registrations',
        link: 'provider/:providerProfileId/registrations',
        allowedRoles: ['Provider'],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'Composite Scores',
        link: 'provider/:providerProfileId/composite-scores',
        allowedRoles: ['Provider'],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'Improvement Plans',
        link: 'provider/:providerProfileId/improvement-plans',
        allowedRoles: ['Provider'],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'Notifications',
        link: 'provider/:providerProfileId/notification',
        allowedRoles: ['Provider'],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      }
    ],
    userActions: [
      {
        name: 'Manage Account',
        providerLink: 'provider/:providerProfileId/account-profile',
        nonProviderLink: 'elc-oel/account-profile',
        providerDependent: true,
        allowedRoles: [
          'Provider',
          'ELC Reviewer',
          'TPITOS Trained Observer',
          'TPOT Coach',
          'OEL Administrator',
          'OEL Read Only',
          'ELC Read Only',
          'TPITOS Coach',
          'FSU 10 Comps Trained Observer',
          'OEL Reviewer',
          'ELC Administrator',
          'TPOT Trained Observer',
          'FSU 10 Comps Coach'
        ],
        getEnabled: function(roles, userProvider) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            const allowedByRoles = this.allowedRoles.some(v =>
              roles.includes(v)
            )

            if (!allowedByRoles) {
              return false
            }

            if (this.providerDependent && roles.includes('Provider')) {
              return !!userProvider?.providerProfileId
            }

            return true
          }
        }
      },
      {
        name: 'EFS Profile',
        providerLink: 'provider/:providerProfileId/profile',
        nonProviderLink: 'provider/:providerProfileId/profile',
        providerDependent: true,
        allowedRoles: ['Provider'],
        getEnabled: function(roles, userProvider) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            const allowedByRoles = this.allowedRoles.some(v =>
              roles.includes(v)
            )

            if (!allowedByRoles) {
              return false
            }

            if (this.providerDependent && roles.includes('Provider')) {
              return !!userProvider?.providerProfileId
            }

            return true
          }
        }
      },
      {
        name: 'Change Password',
        providerLink: 'account/password-change',
        nonProviderLink: 'account/password-change',
        providerDependent: false,
        allowedRoles: [
          'Provider',
          'ELC Reviewer',
          'TPITOS Trained Observer',
          'TPOT Coach',
          'OEL Administrator',
          'OEL Read Only',
          'ELC Read Only',
          'TPITOS Coach',
          'FSU 10 Comps Trained Observer',
          'OEL Reviewer',
          'ELC Administrator',
          'TPOT Trained Observer',
          'FSU 10 Comps Coach'
        ],
        getEnabled: function(roles, userProvider) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            const allowedByRoles = this.allowedRoles.some(v =>
              roles.includes(v)
            )

            if (!allowedByRoles) {
              return false
            }

            if (this.providerDependent && roles.includes('Provider')) {
              return !!userProvider?.providerProfileId
            }

            return true
          }
        }
      }
    ],
    modules: [
      {
        name: 'BPIECE',
        root: 'bpieceUrl',
        providerLink: 'provider-dashboard/:providerProfileId',
        nonProviderLink: 'provider-list',
        trainingLink: 'https://www.youtube.com/watch?v=bkHmh0wOeNg',
        allowedRoles: [
          'OEL Administrator',
          'OEL Reviewer',
          'OEL Read Only',
          'ELC Administrator',
          'ELC Reviewer',
          'ELC Read Only',
          'Provider'
        ],
        getEnabled: function(roles) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            return this.allowedRoles.some(v => roles.includes(v))
          }
        }
      },
      {
        name: 'FSU 10 Components',
        root: 'fsu10Url',
        providerLink: 'provider-dashboard/:providerProfileId',
        nonProviderLink: 'provider-search',
        trainingLink: 'https://www.youtube.com/watch?v=8m4FX5UaIlY',
        allowedRoles: [
          'OEL Administrator',
          'OEL Reviewer',
          'OEL Read Only',
          'ELC Administrator',
          'ELC Reviewer',
          'ELC Read Only',
          'Provider',
          'FSU 10 Comps Trained Observer',
          'FSU 10 Comps Coach'
        ],
        getEnabled: function(roles, userProvider) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            const allowedByRoles = this.allowedRoles.some(v =>
              roles.includes(v)
            )

            if (!allowedByRoles) {
              return false
            }

            if (roles.length > 0 && roles.includes('Provider')) {
              return userProvider?.isFsu10Enabled ?? false
            }

            return true
          }
        }
      },
      {
        name: 'Pyramid Model',
        root: 'pyramidUrl',
        providerLink: 'provider-dashboard/:providerProfileId',
        nonProviderLink: 'provider-search',
        trainingLink: 'https://www.youtube.com/watch?v=Oz1RDnM-agc',
        allowedRoles: [
          'OEL Administrator',
          'OEL Reviewer',
          'OEL Read Only',
          'ELC Administrator',
          'ELC Reviewer',
          'ELC Read Only',
          'Provider',
          'TPITOS Trained Observer',
          'TPOT Coach',
          'TPITOS Coach',
          'TPOT Trained Observer'
        ],
        getEnabled: function(roles, userProvider) {
          if (!roles || roles.length === 0) {
            return false
          } else {
            const allowedByRoles = this.allowedRoles.some(v =>
              roles.includes(v)
            )

            if (!allowedByRoles) {
              return false
            }

            if (roles.length > 0 && roles.includes('Provider')) {
              return userProvider?.isPyramidModelEnabled ?? false
            }

            return true
          }
        }
      }
    ],
    userManagement: [
      {
        name: 'Manage Users',
        root: 'srpaUrl',
        providerLink: 'elc-oel/user-management/list',
        nonProviderLink: 'elc-oel/user-management/list',
        allowedRoles: [
          'OEL Administrator',
          'OEL Reviewer',
          'ELC Administrator',
          'ELC Reviewer'
        ]
      }
    ]
  }),
  computed: {
    roleSections() {
      const localUserRoles = this.userRoles
      const match = /:providerProfileId/i
      const provider = this.userProvider
      const configUrls = this.config

      return this.programAssessmentSections
        .filter(s => s.getEnabled(localUserRoles, provider))
        .map(s => {
          return {
            name: s.name,
            link:
              localUserRoles.includes('Provider') &&
              provider &&
              provider.providerProfileId > 0
                ? configUrls.srpaUrl +
                  s.link.replace(match, provider.providerProfileId)
                : configUrls.srpaUrl + s.link
          }
        })
    },
    roleActions() {
      const localUserRoles = this.userRoles
      const match = /:providerProfileId/i
      const provider = this.userProvider
      const configUrls = this.config

      return this.userActions
        .filter(a => a.getEnabled(localUserRoles, provider))
        .map(a => {
          return {
            name: a.name,
            link:
              localUserRoles.includes('Provider') &&
              provider &&
              provider.providerProfileId > 0
                ? configUrls.srpaUrl +
                  a.providerLink.replace(match, provider.providerProfileId)
                : configUrls.srpaUrl + a.nonProviderLink
          }
        })
    },
    roleModules() {
      const localUserRoles = this.userRoles
      const match = /:providerProfileId/i
      const provider = this.userProvider
      const configUrls = this.config

      return this.modules
        .filter(s => s.getEnabled(localUserRoles, provider))
        .map(function(m) {
          return {
            name: m.name,
            root: m.root,
            trainingLink: m.trainingLink,
            link:
              localUserRoles.includes('Provider') &&
              provider &&
              provider.providerProfileId
                ? configUrls[m.root] +
                  m.providerLink.replace(match, provider.providerProfileId)
                : configUrls[m.root] + m.nonProviderLink
          }
        })
    },
    currentModule() {
      return this.roleModules.filter((m) => { return m.root === this.home })
    },
    roleUserManagement() {
      const localUserRoles = this.userRoles
      const match = /:providerProfileId/i
      const provider = this.userProvider
      const configUrls = this.config

      return this.userManagement
        .filter(s => s.allowedRoles.some(v => localUserRoles.includes(v)))
        .map(m => {
          return {
            name: m.name,
            root: m.root,
            link:
              localUserRoles.includes('Provider') &&
              provider &&
              provider.providerProfileId
                ? configUrls[m.root] +
                  m.providerLink.replace(match, provider.providerProfileId)
                : configUrls[m.root] + m.nonProviderLink
          }
        })
    },
    userRoles() {
      const roles = this.user?.role
      return [roles ?? []].flat()
    },
    userFullName() {
      const fullName = (
        (this.user?.given_name ?? '') +
        ' ' +
        (this.user?.family_name ?? '')
      ).trim()

      return fullName.length ? fullName : this.user?.name ?? ''
    },
    homeLink() {
      if (!this.config) {
        return ''
      }

      const provider = this.userProvider
      const path = this.user
        ? this.userRoles.includes('Provider') && provider?.providerProfileId
          ? 'dashboard/provider/' + provider.providerProfileId
          : 'dashboard/oel-elc/'
        : ''

      return this.config.qpsUrl.length ? this.config.qpsUrl + path : ''
    },
    showStaff() {
      const provider = this.userProvider
      return (
        this.userRoles.includes('Provider') &&
        provider &&
        provider.providerProfileId
      )
    },
    staffLink() {
      if (!this.config) {
        return ''
      }

      const provider = this.userProvider
      const path =
        this.userRoles.includes('Provider') &&
        provider &&
        provider.providerProfileId
          ? 'provider/' + provider.providerProfileId + '/staff-roster'
          : ''

      return this.config.srpaUrl.length && path.length
        ? this.config.srpaUrl + path
        : ''
    },
    showClassrooms() {
      const provider = this.userProvider
      return (
        this.userRoles.includes('Provider') &&
        provider &&
        provider.providerProfileId
      )
    },
    classroomsLink() {
      if (!this.config) {
        return ''
      }

      const provider = this.userProvider
      const path =
        this.userRoles.includes('Provider') &&
        provider &&
        provider.providerProfileId
          ? 'provider/' + provider.providerProfileId + '/classrooms'
          : ''

      return this.config.srpaUrl.length && path.length
        ? this.config.srpaUrl + path
        : ''
    },
    showCalculator() {
      // TODO: remove the explicit false return below and uncommment the previous code when we are ready to include the tuition calculator
      // return false
      const provider = this.userProvider
      return (
        this.userRoles.includes('Provider') &&
        provider &&
        provider.providerProfileId
      )
    },
    calculatorLink() {
      if (!this.config) {
        return ''
      }

      const provider = this.userProvider
      const path =
        this.userRoles.includes('Provider') &&
        provider &&
        provider.providerProfileId
          ? 'provider/' + provider.providerProfileId + '/tuition-calculator'
          : ''

      return this.config.srpaUrl.length && path.length
        ? this.config.srpaUrl + path
        : ''
    },
    activateAccountLink() {
      if (!this.config) {
        return ''
      }

      const path = 'account/account-lookup'

      return this.config.srpaUrl.length && path.length
        ? this.config.srpaUrl + path
        : ''
    },
    contactUsLink() {
      if (!this.config) {
        return ''
      }

      const path = 'contact-us'

      return this.config.qpsUrl.length && path.length
        ? this.config.qpsUrl + path
        : ''
    },
    navigationBarAction: {
      get: function() {
        return this.navbarAction
      },
      set: function(value) {
        this.navbarAction = value
      }
    }
  },
  methods: {
    userLogin() {
      this.drawer = false
      this.navigationBarAction = true
      this.$emit('user-login')
    },
    userSignOut() {
      this.drawer = false
      this.navigationBarAction = true
      this.$emit('user-signout')
    }
  },
  watch: {
    group() {
      this.drawer = false
    },
    actions() {
      this.drawer = false
    },
    sections() {
      this.drawer = false
    }
  }
}
</script>

<style scoped>
.center-nav {
  text-align: center;
}
.user-menu {
  z-index: 100;
}
.fixed-nav-drawer {
  position:fixed;
  top:0;
  left:0;
}
.col-auto {
  margin: -8px;
}
</style>
