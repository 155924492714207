<template>
  <div class="text-center">
    <v-progress-circular :value="value"
                         :indeterminate="indeterminate || true"
                         :color="color || 'primary'"
                         :size="size || 50"
                         :width="width || 5" />
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    indeterminate: Boolean,
    color: String,
    size: Number,
    width: Number
  }
}
</script>
