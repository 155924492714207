<template>
  <div />
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/store/index'
import { defineRulesFor } from '@/config/ability'
import * as usersApi from './../../api/users'

export default {
  name: 'OidcCallback',
  methods: {
    ...mapActions(['oidcSignInCallback'])
  },
  async mounted() {
    this.oidcSignInCallback()
      .then(async () => {
        // CASL Ability updating once user is available ------------
        const ability = store.getters.ability
        const rules = defineRulesFor(store.getters?.oidcUser?.role)
        ability.update(rules)
        this.$store.commit('SET_ABILITY_RULES', rules)
        // ---------------------------------------------------------

        const userRoles = [store.getters?.oidcUser?.role ?? []].flat()

        if (userRoles.includes('Provider')) {
          const res = await usersApi.getUserById(store.getters?.oidcUser.sub)
          if (res?.data?.providerProfileId) {
            const providerProfileId = res.data.providerProfileId
            this.$router
              .push(`/dashboard/provider/${providerProfileId}`)
              .catch(() => {
                /* vue-router will throw error if this route is redirected by route guard */
              })
          } else {
            this.$router.push('/dashboard/oel-elc').catch(() => {
              /* vue-router will throw error if this route is redirected by route guard */
            })
          }
        } else {
          this.$router.push('/dashboard/oel-elc').catch(() => {
            /* vue-router will throw error if this route is redirected by route guard */
          })
        }
      })
      .catch(() => {
        this.$router.push({ name: 'Home' }).catch(() => {
          /* vue-router will throw error if this route is redirected by route guard */
        })
      })
  }
}
</script>
