<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="auto">
        <v-card outlined
                max-width="500"
                tile
                class="mt-5">
          <v-card-title>
            Not Authorized
          </v-card-title>
          <v-card-text class="text-left">
            <p>Your user account is not authorized to view the information that was requested.</p>
            <p>
              Please contact your early learning coalition or the Division of Early Learning if
              you believe that there is an error with your account.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  emits: ['page:initialized'],
  mounted: function() {
    // Wait until child components have loaded, then emit state to app
    this.$nextTick(function() {
      this.$emit('page:initialized')
    })
  }
}
</script>

<style scoped>
</style>
