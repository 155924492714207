const buildTarget = process.env.BUILD_TARGET || 'development'
const configs = {
  development: () => require('./development'),
  staging: () => require('./staging'),
  production: () => require('./production'),
  integration: () => require('./integration'),
  hotfix: () => require('./hotfix')
}
const config = configs[buildTarget]()

export default config.default
