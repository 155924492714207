<template>
  <div id="app"
       class="fadein">
    <v-app v-cloak
           v-if="showApp">
      <div v-if="showNavigation">
        <module-navigation :home="currentModule"
                           :user="oidcUser"
                           :config="this.appConfig"
                           :userProvider="userProvider"
                           :loading="navigationLoading"
                           :initialization="routeInitialized"
                           @user-signout="userLogout"
                           @user-login="userLogin"
                           @enter-module="showOverlay" />
      </div>
      <confirm-dialog v-model="ConfirmDialog"
                      :title="confirmTitle"
                      :message="confirmMessage"
                      @confirm-dialog-closed="confirmCallback($event)" />
      <!-- Environment Banner, based on /config/*.ts environment configs -->
      <div v-if="showEnvironmentBanner">
        <div style="width:200px;float:right">
          <!-- mt-14/16(margin top) matches breakpoint sizing height of the toolbar -->
          <v-card class="pa-1 red white--text font-weight-bold text-caption"
                  :class="{'mt-14': $vuetify.breakpoint.smAndDown, 'mt-16': $vuetify.breakpoint.mdAndUp}"
                  width="100%"
                  style="position:fixed;z-index:7">
            {{ environmentDescription }}
          </v-card>
        </div>
      </div>
      <router-view class="pb-8 mt-14"
                   @page:initialized="showInitialization" />
      <app-footer />
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate
                             size="64" />
      </v-overlay>
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ModuleNavigation, AppFooter } from '@oel/ui-toolkit'

import * as usersApi from './api/users'
import * as providersApi from './api/providers'

export default {
  components: {
    AppFooter,
    ModuleNavigation
  },
  data() {
    return {
      showApp: false,
      ConfirmDialog: false,
      confirmTitle: '',
      confirmMessage: '',
      provider: null,
      navigationLoading: false,
      overlay: false,
      currentModule: 'qpsUrl',
      routeInitialized: false,
      environmentDescription: this.appConfig.environmentDescription,
      showEnvironmentBanner: this.appConfig.showEnvironmentBanner
    }
  },
  methods: {
    ...mapActions(['signOutOidc']),
    userLogin() {
      this.$router.push({ name: 'Dashboard' })
    },
    userLogout() {
      const oidcKeys = Object?.keys(localStorage)?.filter(k => k.startsWith('oidc.'))
      if (oidcKeys?.length) {
        this.signOutOidc()
      } else {
        this.$router.push({ name: 'Dashboard' })
      }
    },
    async getProviderProfileId(id) {
      try {
        if (id) {
          this.navigationLoading = true

          const res = await usersApi.getUserById(id)
          if (res.data.providerProfileId) {
            const res2 = await providersApi.getDashboardDetails(
              res.data.providerProfileId
            )

            this.userProvider = res2.data
          }
        }
      } catch (err) {
      } finally {
        this.navigationLoading = false
      }
    },
    showOverlay(module) {
      this.overlay = module !== this.currentModule
    },
    showInitialization() {
      this.routeInitialized = true
    }
  },
  computed: {
    ...mapGetters(['oidcUser', 'routeFinished']),
    userProvider: {
      get: function() {
        return this.provider
      },
      set: function(value) {
        this.provider = value
      }
    },
    showNavigation() {
      return this.$route.meta?.showNavigation ?? true
    }
  },
  watch: {
    routeFinished: function() {
      this.getProviderProfileId(this?.oidcUser?.sub ?? null)
    }
  },
  mounted() {
    this.$nextTick(function() {
      setTimeout(() => {
        this.showApp = true
      }, 200)
    })
  }
}
</script>

<style>
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("../../packages/oel-common/src/fonts/Roboto-Regular.ttf")
      format("truetype");
}

#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app.fadein #app {
  animation: fade-in 0.5s ease-in;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

[v-cloak] {
  display: none;
}
</style>
