import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability'
import { RolePolicies, Actions, Subjects } from './policy'

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>

export function defineRulesFor(roles: any) {
  const { can, rules } = new AbilityBuilder(AppAbility)

  const userRoles: string[] = [roles ?? []].flat()

  for (const policyName in RolePolicies) {
    for (const policyAction in RolePolicies[policyName]) {
      const allowedRoles: string[] = RolePolicies[policyName][policyAction]
      const matchedRoles: string[] = userRoles.filter(role => allowedRoles.includes(role))
      if (matchedRoles.length) {
        can(Actions[policyAction], Subjects[policyName])
      }
    }
  }

  return rules
}
