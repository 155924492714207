import { configureOelVue, vuetify, Vue } from '@oel/common'
import App from './App.vue'
import { router } from './router/index'
import store from './store/index'
import config from './config'
import { setAxiosRequestInterceptor } from './helpers/axios'
import { abilitiesPlugin } from '@casl/vue'
import { UserManager } from 'oidc-client'
import { oidcSettings } from '@/config/oidc'
import './css/site.less'

configureOelVue(
  config,
  config.apiUrl,
  config.srpaUrl,
  '#682f93'
)

// expose the config variables to the application components
Vue.prototype.appConfig = config

Vue.use(abilitiesPlugin, store.getters.ability)

// this extends the functionality of oel-common axios config
setAxiosRequestInterceptor()

let app: any = null

Vue.prototype.$confirm = (message, title) => {
  const promise = new Promise((resolve, reject) => {
    try {
      // DISCUSS this really should not have a reference to 'app' here. Consider using vuex or some other alternative instead
      app.confirmTitle = title == null ? 'Confirm' : title
      app.confirmMessage = message
      app.confirmCallback = (val) => {
        resolve(val)
      }
    } catch (err) {
      reject(err)
    }
  })
  app.ConfirmDialog = true
  return promise
}

Vue.prototype.$oidcUserManager = new UserManager(oidcSettings)

app = new Vue({
  router,
  store,
  vuetify,
  ...App,
  beforeCreate() {
    // this exists to read the users casl rules back from localstorage if the page is refreshed
    const ability = store.getters.ability
    const rules = store.getters.getCaslRules
    ability.update(rules)
  }
} as any)

app.$mount('#app')
