/* eslint-disable no-unused-vars */

export enum Subjects {
    Account = 'Account',
    UserManagement = 'UserManagement',
    ProviderDashboard = 'ProviderDashboard',
    NonProviderDashboard = 'NonProviderDashboard',
    Staff = 'Staff',
    Classroom = 'Classroom',
    SRPACard = 'SRPACard',
    FSU10Card = 'FSU10Card',
    BPIECECard = 'SRPACard',
    PyramidCard = 'PyramidCard'
}

export enum Actions {
    Create = 'Create',
    Read = 'Read',
    Update = 'Update',
    Delete = 'Delete'
}

export enum QPSRoles {
    Provider = 'Provider',
    ELCAdmin = 'ELC Administrator',
    ELCReviewer = 'ELC Reviewer',
    ELCReadOnly = 'ELC Read Only',
    OELAdministrator = 'OEL Administrator',
    OELReviewer = 'OEL Reviewer',
    OELReadOnly = 'OEL Read Only',
    FSU10CompsTrainedObserver = 'FSU 10 Comps Trained Observer',
    FSU10CompsCoach = 'FSU 10 Comps Coach',
    TPOTTrainedObserver = 'TPOT Trained Observer',
    TPOTCoach = 'TPOT Coach',
    TPITOSTrainedObserver = 'TPITOS Trained Observer',
    TPITOSCoach = 'TPITOS Coach'
}

type ActionRoles = Record<Actions, QPSRoles[]>

export const RolePolicies: Record<Subjects, ActionRoles> = {
  [Subjects.Account]: {
    [Actions.Create]: [],
    [Actions.Read]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.FSU10CompsCoach,
      QPSRoles.FSU10CompsTrainedObserver,
      QPSRoles.TPOTCoach,
      QPSRoles.TPOTTrainedObserver,
      QPSRoles.TPITOSCoach,
      QPSRoles.TPITOSTrainedObserver
    ],
    [Actions.Update]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.FSU10CompsCoach,
      QPSRoles.FSU10CompsTrainedObserver,
      QPSRoles.TPOTCoach,
      QPSRoles.TPOTTrainedObserver,
      QPSRoles.TPITOSCoach,
      QPSRoles.TPITOSTrainedObserver
    ],
    [Actions.Delete]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.FSU10CompsCoach,
      QPSRoles.FSU10CompsTrainedObserver,
      QPSRoles.TPOTCoach,
      QPSRoles.TPOTTrainedObserver,
      QPSRoles.TPITOSCoach,
      QPSRoles.TPITOSTrainedObserver
    ]
  },
  [Subjects.UserManagement]: {
    [Actions.Create]: [
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ],
    [Actions.Read]: [
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ],
    [Actions.Update]: [
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ],
    [Actions.Delete]: [
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer
    ]
  },
  [Subjects.Staff]: {
    [Actions.Create]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.FSU10CompsCoach,
      QPSRoles.FSU10CompsTrainedObserver,
      QPSRoles.TPOTCoach,
      QPSRoles.TPOTTrainedObserver,
      QPSRoles.TPITOSCoach,
      QPSRoles.TPITOSTrainedObserver
    ],
    [Actions.Read]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.FSU10CompsCoach,
      QPSRoles.FSU10CompsTrainedObserver,
      QPSRoles.TPOTCoach,
      QPSRoles.TPOTTrainedObserver,
      QPSRoles.TPITOSCoach,
      QPSRoles.TPITOSTrainedObserver
    ],
    [Actions.Update]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.FSU10CompsCoach,
      QPSRoles.FSU10CompsTrainedObserver,
      QPSRoles.TPOTCoach,
      QPSRoles.TPOTTrainedObserver,
      QPSRoles.TPITOSCoach,
      QPSRoles.TPITOSTrainedObserver
    ],
    [Actions.Delete]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.FSU10CompsCoach,
      QPSRoles.FSU10CompsTrainedObserver,
      QPSRoles.TPOTCoach,
      QPSRoles.TPOTTrainedObserver,
      QPSRoles.TPITOSCoach,
      QPSRoles.TPITOSTrainedObserver
    ]
  },
  [Subjects.Classroom]: {
    [Actions.Create]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.FSU10CompsCoach,
      QPSRoles.FSU10CompsTrainedObserver,
      QPSRoles.TPOTCoach,
      QPSRoles.TPOTTrainedObserver,
      QPSRoles.TPITOSCoach,
      QPSRoles.TPITOSTrainedObserver
    ],
    [Actions.Read]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.FSU10CompsCoach,
      QPSRoles.FSU10CompsTrainedObserver,
      QPSRoles.TPOTCoach,
      QPSRoles.TPOTTrainedObserver,
      QPSRoles.TPITOSCoach,
      QPSRoles.TPITOSTrainedObserver
    ],
    [Actions.Update]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.FSU10CompsCoach,
      QPSRoles.FSU10CompsTrainedObserver,
      QPSRoles.TPOTCoach,
      QPSRoles.TPOTTrainedObserver,
      QPSRoles.TPITOSCoach,
      QPSRoles.TPITOSTrainedObserver
    ],
    [Actions.Delete]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.FSU10CompsCoach,
      QPSRoles.FSU10CompsTrainedObserver,
      QPSRoles.TPOTCoach,
      QPSRoles.TPOTTrainedObserver,
      QPSRoles.TPITOSCoach,
      QPSRoles.TPITOSTrainedObserver
    ]
  },
  [Subjects.ProviderDashboard]: {
    [Actions.Create]: [
      QPSRoles.Provider
    ],
    [Actions.Read]: [
      QPSRoles.Provider
    ],
    [Actions.Update]: [
      QPSRoles.Provider
    ],
    [Actions.Delete]: [
      QPSRoles.Provider
    ]
  },
  [Subjects.NonProviderDashboard]: {
    [Actions.Create]: [
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly
    ],
    [Actions.Read]: [
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.FSU10CompsCoach,
      QPSRoles.FSU10CompsTrainedObserver,
      QPSRoles.TPOTCoach,
      QPSRoles.TPOTTrainedObserver,
      QPSRoles.TPITOSCoach,
      QPSRoles.TPITOSTrainedObserver
    ],
    [Actions.Update]: [
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly
    ],
    [Actions.Delete]: [
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly
    ]
  },
  [Subjects.SRPACard]: {
    [Actions.Create]: [],
    [Actions.Read]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly
    ],
    [Actions.Update]: [],
    [Actions.Delete]: []
  },
  [Subjects.FSU10Card]: {
    [Actions.Create]: [],
    [Actions.Read]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.FSU10CompsCoach,
      QPSRoles.FSU10CompsTrainedObserver
    ],
    [Actions.Update]: [],
    [Actions.Delete]: []
  },
  [Subjects.BPIECECard]: {
    [Actions.Create]: [],
    [Actions.Read]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly
    ],
    [Actions.Update]: [],
    [Actions.Delete]: []
  },
  [Subjects.PyramidCard]: {
    [Actions.Create]: [],
    [Actions.Read]: [
      QPSRoles.Provider,
      QPSRoles.ELCAdmin,
      QPSRoles.ELCReviewer,
      QPSRoles.ELCReadOnly,
      QPSRoles.OELAdministrator,
      QPSRoles.OELReviewer,
      QPSRoles.OELReadOnly,
      QPSRoles.TPOTCoach,
      QPSRoles.TPOTTrainedObserver,
      QPSRoles.TPITOSCoach,
      QPSRoles.TPITOSTrainedObserver
    ],
    [Actions.Update]: [],
    [Actions.Delete]: []
  }
}
