export default {
  apiUrl: 'https://staginggateway-api-qps.floridaearlylearning.com/',
  oidcAuthority: 'https://stagingauth-qps.floridaearlylearning.com/',
  oidcRedirectUri: 'https://stagingportal-qps.floridaearlylearning.com/oidc-callback',
  oidcSilentRedirectUri: 'https://stagingportal-qps.floridaearlylearning.com/oidc-silent-renew',
  oidcPostLogoutRedirectUri: 'https://stagingportal-qps.floridaearlylearning.com/oidc-signout',
  bpieceUrl: 'https://stagingbpiece-qps.floridaearlylearning.com/',
  pyramidUrl: 'https://stagingpyramid-qps.floridaearlylearning.com/',
  fsu10Url: 'https://staging10components-qps.floridaearlylearning.com/',
  qpsUrl: 'https://stagingportal-qps.floridaearlylearning.com/',
  srpaUrl: 'https://stagingqps.floridaearlylearning.com/',
  qpsSignoutRedirectUrl: 'https://stagingportal-qps.floridaearlylearning.com/oidc-signout-redirect',
  oidcScopes: 'openid profile qpsScope roles',
  vuexEncryptionKey: 'C0HhdGsw8f7ntvLxT2UuCNpEOAkszL395BhUV',
  showEnvironmentBanner: true,
  environmentDescription: 'UAT/Staging'
}
