<template>
  <v-dialog v-if="genericDialog"
            v-model="genericDialog"
            :width="width || `500px`"
            :persistent="persistent"
            :scrollable="scrollable">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        <span v-html="title" />
      </v-card-title>

      <v-card-text class="text-left pt-4"
                   v-scroll.self="onScroll">
        <slot name="card-text"
              v-if="!loading" />
        <loading-spinner v-if="loading"
                         :size="200"
                         :width="15"
                         :indeterminate="false"
                         class="pt-8" />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <slot name="card-actions" />
      </v-card-actions>

      <v-expand-transition>
        <v-card v-if="showOverlay"
                class="transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;">
          <v-card-text class="p-4">
            <p class="text-h6 text-lg-h4 primary--text text-left"
               v-html="overlayTitle" />
            <p class="text-caption text-lg-body-2 text-left"
               v-html="overlayText" />
          </v-card-text>
          <v-card-actions class="pt-0">
            <div v-if="overlayActions">
              <v-btn text
                     color="primary"
                     @click="overlayResponse('Yes')">
                Yes
              </v-btn>
              <v-btn text
                     color="secondary"
                     @click="overlayResponse('No')">
                No
              </v-btn>
            </div>
            <div v-else>
              <v-btn text
                     color="primary"
                     @click="overlayResponse(null)">
                Continue
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-expand-transition>
    </v-card>
  </v-dialog>
</template>

<script>
import LoadingSpinner from './loading-spinner.vue'
export default {
  emits: ['scroll:complete', 'overlay-response'],
  props: {
    title: String,
    value: Boolean,
    persistent: Boolean,
    scrollable: Boolean,
    width: String,
    loading: Boolean,
    overlayText: {
      type: String,
      default: ''
    },
    overlayTitle: {
      type: String,
      default: ''
    },
    overlayActions: {
      type: Boolean,
      default: false
    },
    overlayDefault: {
      type: Boolean,
      default: true
    }
  },
  components: { LoadingSpinner },
  data() {
    return {
      showOverlay: false
    }
  },
  computed: {
    genericDialog: {
      get: function() {
        return this.value
      },
      set: function(val) {
        if (!val) {
          this.$emit('close-dialog')
        }
      }
    }
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.$emit('scroll:complete')
      }
    },
    overlayResponse(response) {
      this.showOverlay = false

      if (response) {
        this.$emit('overlay:response', response)
      }
    }
  },
  created() {
    this.showOverlay = this.overlayDefault && this.overlayText.length > 0
  },
  watch: {
    overlayDefault() {
      this.showOverlay = this.overlayDefault
    }
  }
}
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
