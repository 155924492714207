<template>
  <v-footer class="white--text footer py-6 px-0"
            absolute
            color="primary">
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-row>
            <v-col cols="12"
                   class="py-0 pl-6">
              <a target="_blank"
                 :href="oelUrl">
                <v-icon color="white"
                        class="footer-icon">mdi-launch</v-icon>
                <span>{{ organizationName }}</span>
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="py-0 pl-6">
              <a target="_blank"
                 :href="fldoeUrl">
                <v-icon color="white"
                        class="footer-icon">mdi-launch</v-icon>
                <span>{{ departmentName }}</span>
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="py-0 pl-6">
              <a target="_blank"
                 :href="googleMapsUrl">
                <v-icon color="white"
                        class="footer-icon">mdi-map</v-icon>
                <span>{{ address.address1 }}</span>
              </a>
            </v-col>
          </v-row>
          <v-row v-if="address.address2">
            <v-col cols="12"
                   class="py-0 pl-6">
              <a class="pl-4"
                 target="_blank"
                 :href="googleMapsUrl">&nbsp;{{ address.address2 }}</a>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="py-0 pl-6">
              <a class="pl-4"
                 target="_blank"
                 :href="googleMapsUrl">&nbsp;{{ address.city }}, {{ address.state }} {{ address.zip }}</a>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="py-0 pl-6">
              <a :href="'tel:' + phoneNumber">
                <v-icon color="white"
                        class="footer-icon">mdi-phone</v-icon>
                <span>{{ phoneNumber }}</span>
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
                   class="py-0 pl-6">
              <a :href="'tel:' + phoneNumberTollFree">
                <v-icon color="white"
                        class="footer-icon">mdi-phone</v-icon>
                <span>{{ phoneNumberTollFree }}</span>
              </a>
            </v-col>
          </v-row>
          <v-row class="text-truncate">
            <v-col cols="12"
                   class="py-0 px-6">
              <a :href="'mailto:' + email">
                <v-icon color="white"
                        class="footer-icon">mdi-email</v-icon>
                <span>{{ email }}</span>
              </a>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6">
          <slot name="custom-footer" />
          <v-row>
            <v-col cols="12"
                   class="py-0 px-6">
              &copy;{{ year }} {{ copyrightText }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      organizationName: 'The Division of Early Learning',
      departmentName: 'Florida Department of Education',
      address: {
        address1: '325 W. Gaines Street',
        address2: null,
        city: 'Tallahassee',
        state: 'FL',
        zip: '32399'
      },
      phoneNumber: '850-717-8550',
      phoneNumberTollFree: '866-357-3239',
      email: 'qualityperformance@del.fldoe.org',
      oelUrl: 'http://www.floridaearlylearning.com/',
      fldoeUrl: 'http://www.fldoe.org/',
      copyrightText: 'The Division of Early Learning is an equal opportunity employer/program. Auxiliary aids and services are available for individuals with disabilities.',
      year: (new Date()).getFullYear(),
      googleMapsUrl: 'https://goo.gl/maps/Q1LkUZhYt4K2'
    }
  }
}

</script>

<style scoped>
  a {
    color: white !important;
    text-decoration: none !important;
  }

  .footer {
    text-align: left;
    overflow-x: hidden !important;
    overflow-y: hidden !important;
    box-shadow: 0 -3px 5px -1px rgba(0,0,0,.2),0 -6px 10px 0 rgba(0,0,0,.14),0 -1px 18px 0 rgba(0,0,0,.12) !important;
    font-size: 14px !important;
    border-radius: 0px !important;
    bottom: -175px !important;
  }

  .footer-icon {
    font-size: 18px !important;
    padding-right: 5px !important;
  }
</style>
