import { getField, updateField } from 'vuex-map-fields'

const state = {
  users: [],
  user: {}
}

const mutations = {
  updateField,
  setUsers(state, users) {
    state.users = users
  }
}

const getters = {
  getField,
  getusers: (state) => {
    return state.users
  }
}

const actions = {
  setUsers({ commit }, users) {
    commit('setUsers', users)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
